import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { breakpoint } from '../../../config'

import { Container } from '../../container'
import { Content } from '../../content'
import { Preview } from './preview'
import { Filter } from '../../filter'
import { Intro } from '../../intro'

import chocolateImg from '../../../assets/chocolate.svg'

const Items = styled.ul`
  margin: 0 -20px;
  padding: 0;
  list-style: none;

  @media ${breakpoint.tablet} {
    margin: 0;
  }

  @media ${breakpoint.tablet} {
    margin: -25px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${breakpoint.tablet} {
    margin: 0 !important;
    padding: 25px;
  }

  @media ${breakpoint.desktop} {
    padding: 25px;
    width: 50%;
  }
`

export function Archive({ posts, categories, currentCategory }) {
  const [currentActiveSlug, setCurrentActiveSlug] = useState(
    currentCategory ? currentCategory.slug : 'ALL'
  )

  let filterItems = categories.map((category) => {
    return {
      key: category.slug,
      label: `${category.title} (${category.count})`,
    }
  })

  filterItems.push({ key: 'ALL', label: 'Alle ansehen' })

  function handleFilterChange(item) {
    setCurrentActiveSlug(item.key)

    if (item.key === 'ALL') {
      navigate('/news/')
    } else {
      navigate(`/news/kategorie/${item.key}/`)
    }
  }

  return (
    <Container>
      <Content
        sidebar={
          <Filter
            label="Kategorien"
            items={filterItems}
            onChange={handleFilterChange}
            defaultActive={currentActiveSlug}
          />
        }
      >
        <Intro icon={chocolateImg}>News</Intro>
        <Items>
          {posts.map((post) => (
            <Item key={post.id}>
              <Preview post={post} />
            </Item>
          ))}
        </Items>
      </Content>
    </Container>
  )
}
